import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apiurls from './_apiurls'
import './App.scss';
import FadeLoader from "react-spinners/FadeLoader";
import { 
  BrowserRouter as Router, 
  Route, 
  Routes
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDatabase, ref, onValue } from 'firebase/database';
import {firebaseConfig} from './firebaseConfig'
import firebase from 'firebase/compat/app';

const loading = () => <FadeLoader 
                        css={"position: fixed;top: 50%;left: 50%;"}
                        size={50}
                        color={"orange"}
                      />;

// Containers
const ThreePageFlipComponent = React.lazy(() => import('./components/ThreePageFlipComponent'));
const BowlersScoreCardComponent = React.lazy(() => import('./components/BowlersScoreCardComponent'));
const BatsmenScoreCardComponent = React.lazy(() => import('./components/BatsmenScoreCardComponent'));
const ScoreCardPage = React.lazy(() => import('./pages/ScoreCardPage'));
const PointsTablePage = React.lazy(() => import('./pages/PointsTablePage'));
const EventSchedulePage = React.lazy(() => import('./pages/EventSchedulePage'));
const TodaysSchedulePage = React.lazy(() => import('./pages/TodaysSchedulePage'));
const EventPointFlipPage = React.lazy(() => import('./pages/EventPointFlipPage'));
const FinalMatchSummaryComponent = React.lazy(() => import('./components/FinalMatchSummaryComponent'));
const FullEventPage = React.lazy(() => import('./pages/FullEventPage'));

firebase.initializeApp(firebaseConfig);


function App(props) {

  const dispatch = useDispatch();

  const [eventSchedule, setEventSchedule] = useState([])
  const [pointsTable, setPointsTable] = useState([])
  const [scoreCardData, setscoreCardData] = useState({})


  useEffect(() => {
    axios.get(apiurls.items["get-event-schedule"])
    .then(res => {
        if(res.status === 200){
          setEventSchedule(res.data)
          dispatch({type:"SET_EVENTSCHEDULE",payload:res.data})
        }
    })

    axios.get(apiurls.items["get-points-table"])
    .then(res => {
        if(res.status === 200){
          setPointsTable(res.data)
          dispatch({type:"SET_POINTSTABLE",payload:res.data})
        }
    })

    axios.get(apiurls.items["get-score-board"])
    .then(res => {
        if(res.status === 200){
          setscoreCardData(res.data)
          if(res.data.match_status !== "not_started"){
            dispatch({type:"SET_SCOREBOARD",payload:res.data})
          }
        }
    })
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const db = getDatabase();

    const dataRef = ref(db, 'data');
    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      const keys = Object.keys(data);
      const lastPushedData = data[keys[keys.length - 1]];

      setscoreCardData(lastPushedData)
      dispatch({type:"SET_SCOREBOARD",payload:lastPushedData})
    });

    const signalRef = ref(db, 'refreshSignal');
    onValue(signalRef, (snapshot) => {
      const data = snapshot.val();
      const keys = Object.keys(data);
      const lastPushedData = data[keys[keys.length - 1]];

      const refreshSignal = localStorage.getItem('refreshSignal');
      if(refreshSignal === null) {
        localStorage.setItem('refreshSignal', lastPushedData);
      }else{
        if(refreshSignal !== lastPushedData) {
          localStorage.setItem('refreshSignal', lastPushedData);
          window.location.reload();
        }
      }

    });
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Routes>
          <Route exact path="/" name="Score Card" element={<ScoreCardPage />} />
          <Route exact path="/points-table" name="Points Table" element={<PointsTablePage />} />
          <Route exact path="/event-schedule" name="Event Schedule" element={<EventSchedulePage />} />
          <Route exact path="/todays-schedule" name="Todays Schedule" element={<TodaysSchedulePage eventSchedule={eventSchedule} />} />
          <Route exact path="/three-page-flip" name="Points Table, Event Schedule & Todays Schedule" element={<ThreePageFlipComponent eventSchedule={eventSchedule} pointsTable={pointsTable}/>} />
          <Route exact path="/bowlers-score-card" name="Bowlers Score Card" element={<BowlersScoreCardComponent scoreCardData={scoreCardData}/>} />
          <Route exact path="/batsmen-score-card" name="Batsmen Score Card" element={<BatsmenScoreCardComponent scoreCardData={scoreCardData}/>} />
          <Route exact path="/event-schedule-points" name="Event Schedule and Points Page" element={<EventPointFlipPage />} />
          <Route exact path="/final-match-summary" name="Final Match Summary" element={<FinalMatchSummaryComponent />} />
          <Route exact path="/full-event-schedule" name="Event Schedule" element={<FullEventPage />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
