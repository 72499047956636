import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// Initialize Firebase with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBaPkb1FxOUmtXROALFD_oeeFLdb0TAwts",
  authDomain: "crickfeed-92e1f.firebaseapp.com",
  databaseURL: "https://crickfeed-92e1f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "crickfeed-92e1f",
  storageBucket: "crickfeed-92e1f.appspot.com",
  messagingSenderId: "276556891690",
  appId: "1:276556891690:web:1fe59dd98116a033892553",
  measurementId: "G-3J33Z89EN0"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Access the Realtime Database
const db = getDatabase(firebaseApp);

export {firebaseConfig}