const serverUrl = "https://api.opmaint.com/"

const data = {
    items: {
        "root": serverUrl,
        "get-score-board": serverUrl + "get-score-board/",
        "get-event-schedule": serverUrl + "get-event-schedule/",
        "get-flag": serverUrl + "get-flag/",
        "get-points-table": serverUrl + "get-points-table/",
        "get-full-event": serverUrl + "get-full-event/",
        "set-match-key": serverUrl + "set-match-key/",
    }
}

export default data;