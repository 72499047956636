
const initState = {
    uuid: "",
    points_table: [],
    event_schedule: [],
    score_card: {}
}

export const reducer = (globalState = initState, action) => {
    switch (action.type) {
        case "SET_UUID": {
            return {
                ...globalState,
                map_data:action.payload
            }
        }
        case "SET_POINTSTABLE": {
            return {
                ...globalState,
                points_table:action.payload
            }
        }
        case "SET_EVENTSCHEDULE": {
            return {
                ...globalState,
                event_schedule:action.payload
            }
        }
        case "SET_SCOREBOARD": {
            return {
                ...globalState,
                score_card:action.payload
            }
        }
        default:
          return globalState
    }
}

export default reducer